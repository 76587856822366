import "aos/dist/aos.css";
import { useEffect, useRef, useState } from "react";
import hero from "./assets/images/photo_2024-09-01_13-35-44.jpg";
import next from "./assets/images/pngwing.png";
import ww from "./assets/images/ww.png";
import { Container } from "./components/GridSystem";
import {
  AboutUs,
  ContactUs,
  Footer,
  Navbar,
  Products,
  ProductsDetails,
  Services,
} from "./pages";
import { langDiv } from "./contexts/useLang";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
const App = () => {
  const [openProduct, setOpenProduct] = useState({});
  const [target, setTarget] = useState("");
  const aboutUs = useRef();
  const products = useRef();
  const services = useRef();
  const contactUs = useRef();
  useEffect(() => {
    if (target === "about_us") {
      aboutUs?.current.scrollIntoView({ behavior: "smooth" });
      setTarget("");
    } else if (target === "products") {
      products?.current.scrollIntoView({ behavior: "smooth" });
      setTarget("");
    } else if (target === "services") {
      services?.current.scrollIntoView({ behavior: "smooth" });
      setTarget("");
    } else if (target === "contact_us") {
      contactUs?.current.scrollIntoView({ behavior: "smooth" });
      setTarget("");
    } else {
    }
  }, [target]);
  useEffect(() => {
    document.documentElement.dir =
      (localStorage.getItem("language") === "ar" &&
        localStorage.getItem("language")) ||
      (langDiv === "ar" && !localStorage.getItem("language"))
        ? "rtl"
        : "ltr";
  }, []);

  return (
    <div className=" relative">
      <div
        onClick={() => {
          window.scrollTo({ behavior: "smooth", top: 0 });
          setTarget("");
        }}
        className={`fixed md:bottom-10 bottom-4 right-2 md:right-4  px-2 py-3  rounded-lg z-20 cursor-pointer bg `}
      >
        <a href="https://wa.me/+971525880561" target="_blank">
          <img
            src={ww}
            alt=""
            className="!cursor-pointer relative -z-10 -rotate-90 w-20 md:w-24 animate-pulse"
          />
        </a>
      </div>
      <Navbar setTarget={setTarget} />
      <Routes>
        <Route
          path="/"
          element={
            <div className="overflow-hidden">
              <div className="md:h-[80vh] rounded-b-[130px] overflow-hidden mb-10 relative">
                <img
                  src={hero}
                  alt=""
                  className=" w-full h-full max-md:object-contain"
                />
              </div>
              <Container>
                <div ref={aboutUs}>
                  <AboutUs />
                </div>
                <ProductsDetails openProduct={openProduct} />
                <div ref={products}>
                  <Products
                    setOpenProduct={setOpenProduct}
                    openProduct={openProduct}
                  />
                </div>
                <div ref={services}>
                  <Services setTarget={setTarget} />
                </div>
                <div ref={contactUs}>
                  <ContactUs />
                </div>
              </Container>
            </div>
          }
        />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      </Routes>

      <Footer />
    </div>
  );
};

export default App;
